import React, { useRef, useState, useEffect } from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';
import Button from './button';

import { transition } from '../components/config';
import { useSpring, animated } from 'react-spring';

import '../styles/components/card.scss';

const Card = ( { title, articleCount, description, imagePath, url, buttonText } ) => {

    const iconRef = useRef();

    const [ mousePosition, setMousePosition ] = useState( { x: 0, y: 0 } );
    const [ iconPosition, setIconPosition ] = useState( { x: 0, y: 0 } );

    const { transform } = useSpring( {
        transform: `translate3d(${iconPosition.x}px, ${iconPosition.y}px, 0)`,
        config: { mass: .7, tension: 300, friction: 12 }
    } );

    useEffect( () => {
        if ( iconRef.current ) {
            setIconPosition( {
                x: ( ( mousePosition.x - iconRef.current.getBoundingClientRect().width / 2 ) / 2 ),
                y: ( ( mousePosition.y - iconRef.current.getBoundingClientRect().height / 2 ) / 2 )
            } );
        }
    }, [ mousePosition ] );

    useEffect( () => {
        setIconPosition( { x: 0, y: 0 } );
    }, [] );

    const onMouseMove = ( event ) => {
        setMousePosition( {
            x: event.pageX - iconRef.current.getBoundingClientRect().left,
            y: event.pageY - iconRef.current.getBoundingClientRect().top - document.documentElement.scrollTop
        } );
    };

    const onMouseLeave = () => {
        setIconPosition( { x: 0, y: 0 } );
    };

    return <div className="card col-3 col-sm-5 col-xs-12">
        <TransitionLink to={url} {...transition}>
            {imagePath && <div className="image-container" onMouseMove={onMouseMove} onMouseLeave={onMouseLeave} ref={iconRef}>
                <animated.div className="image" style={{ transform, backgroundImage: `url(${imagePath})` }}></animated.div>
            </div>}
            <h3>
                {title}
                {( articleCount !== null ) &&
                    <sup>{articleCount}</sup>
                }
            </h3>
            <p>{description}</p>
            <Button small>{buttonText}</Button>
        </TransitionLink>
    </div>;

};

export default Card;
