import React, { useEffect, useContext } from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';
import Feedback from '../components/feedback';
import Button from '../components/button';
import Card from '../components/card';
import Page from '../components/page';
import SEO from '../components/seo';

import { PageWrapperContext } from '../components/page-wrapper';
import { transition } from '../components/config';

import styles from '../styles/pages/chapter.module.scss';

export default function Template( { pageContext, transitionStatus } ) {

    const { node, articles } = pageContext;
    const { frontmatter } = node;
    const { id, title, description } = frontmatter;

    const pageWrapperContext = useContext( PageWrapperContext );

    useEffect( () => {
        pageWrapperContext.setPageClass( `page-chapter page-${id}` );
    }, [] );

    const renderArticles = () => {
        if ( articles.length === 0 ) {
            return <div className={`col-9 col-xs-12 ${styles.placeholder}`}>
                <p>There are no articles for this chapter yet. This playbook is far from complete: cross-border collaboration is an increasingly dynamic and complex journalistic discipline and we were unable to cover everything about it. Do you have experience with this topic that you would to add to this playbook?</p>
                <a href="mailto:playbook@n-ost.org" title="playbook@n-ost.org"><Button expanded small>Pitch your idea</Button></a>
            </div>;
        } else {
            return articles.map( ( article, index ) => {
                const { shortTitle, shortDescription, slug } = article.frontmatter;
                return <Card
                    key={index}
                    title={shortTitle}
                    description={shortDescription}
                    url={slug}
                    buttonText="Read article"
                />;
            } );
        }
    };

    return <Page transitionStatus={transitionStatus}>
        <SEO title={title} description={description} image={`/images/opengraph/${id}.jpg`} />
        <section className="container-fluid">
            <div className={`row ${styles.title}`}>
                <div className="col-9 col-xs-12">
                    <h1>{title}<sup>{articles.length}</sup></h1>
                </div>
                <div className="col-2 col-xs-12 col-align-left">
                    <TransitionLink to='/chapters' {...transition}>
                        <Button reversed>Back</Button>
                    </TransitionLink>
                </div>
                <div className="col col-9 col-xs-12">
                    <h3>{description}</h3>
                </div>
            </div>
            <div className="row">
                {renderArticles()}
            </div>
        </section>
        <Feedback />
    </Page>;
}
